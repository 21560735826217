import * as Sentry from "@sentry/vue";

/**
 * This is our Frontend integration of Sentry using the @sentry/vue SDK.
 * Doc: https://docs.sentry.io/platforms/javascript/guides/vue/
 */
export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const {
    public: { sentry },
  } = useRuntimeConfig();

  if (!sentry.dsnFront) {
    return;
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsnFront,
    environment: sentry.environment,
    // until we find a solution, we disable Sentry for the frontend
    enabled: false,
    integrations: [
      // used to track the performance of the application
      Sentry.browserTracingIntegration({ router }),
      // replays are video recordings of user sessions
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    // These values need to be between 0.0 and 1.0 and represent the percentage of traces to capture
    // It's how much we sample from all events
    tracesSampleRate: 1.0,
    // of sessions recorded
    replaysSessionSampleRate: 1.0,
    // and of errors captured
    replaysOnErrorSampleRate: 0.25,

    beforeSend(event, hint) {
      // hint is an object that contains the original exception and the reason why the event was captured
      // This is a custom fingerprint for Zod validation errors - a fingerprint is like a tag that can be used to group similar events
      const error = hint.originalException as any;
      const zodRegex = /(requis|n'est pas valide|ZodError|Veuillez remplir tous les champs)/g;
      if (error && error.message && error.message.match(zodRegex)) {
        event.fingerprint = ["zod-validation-error"];
        event.tags = { ...event.tags, errorType: "ZodValidationError" };
      }
      return event;
    },
  });
});
