const PRODUCTION_HOSTNAMES = ["capeb.asap.work", "x.asap.work"];
const STAGING_HOSTNAMES = ["staging.x.asap.work"];
const DEVELOPMENT_HOSTNAMES = ["localhost"];

export const featureFlag = function (url: URL) {
  const isProduction = PRODUCTION_HOSTNAMES.includes(url.hostname);
  const isStaging = STAGING_HOSTNAMES.includes(url.hostname);
  const isDevelopment = DEVELOPMENT_HOSTNAMES.includes(url.hostname);

  const isNotProduction = isStaging || isDevelopment;

  const environment = isProduction ? "production" : isStaging ? "staging" : "local";

  const appName = `back_office_${environment}`;

  return {
    isProduction,
    isStaging,
    isDevelopment,
    isNotProduction,
    environment,
    appName,
  };
};
