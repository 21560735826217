<template>
  <SegmentAnalytics>
    <ToastContainer>
      <TooltipProvider :delay-duration="100">
        <NuxtLayout>
          <NuxtPage />
        </NuxtLayout>
      </TooltipProvider>
    </ToastContainer>
  </SegmentAnalytics>
</template>

<script setup lang="ts">
// This may fix the "ResizeObserver loop completed with undelivered notifications" error.
import "~/utils/resize-observer";
import * as Sentry from "@sentry/vue";

// It may fix the "bug parasite" where the server doesn't see any cookie and the user is rejected with 401, but we'll see in production as it doesn't affect everyone
// https://github.com/nuxt-modules/supabase/issues/300#issuecomment-1793667652
const supabase = useSupabaseClient();

const { cookieOptions } = useRuntimeConfig().public.supabase;

const accessToken = useCookie("sb-access-token", {
  maxAge: cookieOptions.maxAge,
  path: "/",
  sameSite: cookieOptions.sameSite,

  // secure: cookieOptions.secure // if this is enabled it doesn't work anymore
});

const refreshToken = useCookie("sb-refresh-token", {
  maxAge: cookieOptions.maxAge,
  path: "/",
  sameSite: cookieOptions.sameSite,
});

const { authUser } = useUserMe();

// listen for auth changes, set/delete cookies
supabase.auth.onAuthStateChange((event, session) => {
  if (event === "SIGNED_OUT") {
    // delete cookies on sign out
    accessToken.value = null;
    refreshToken.value = null;
  } else if (event === "SIGNED_IN" || event === "TOKEN_REFRESHED") {
    // set cookies on sign in
    accessToken.value = session?.access_token;
    refreshToken.value = session?.refresh_token;
  }
});

const isSentryUserSet = ref(false);

watchEffect(() => {
  if (authUser.value?.email && !isSentryUserSet.value) {
    Sentry.setUser({ email: authUser.value?.email, id: authUser.value?.id, username: getFullName(authUser.value) });
    isSentryUserSet.value = true;
  }
});
</script>

<style lang="scss">
#__nuxt {
  height: 100%;
}
</style>
