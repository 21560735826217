import { createSharedComposable } from "@vueuse/core";
import { teamMemberColumns, workspaceColumns } from "~/utils/supabase.columns";
import type { TeamMember, Workspace } from "~/utils/supabase.types";
import { ASAP_WORKSPACE_ID } from "~/utils/workspace";

export const useWorkspaceMe = createSharedComposable(function () {
  const { workspaceId } = useUserMe();

  const workspace = ref<Workspace>();

  const isAsapWorkspace = computed<boolean>(() => workspaceId.value === ASAP_WORKSPACE_ID);
  const isIndependentWorkspace = computed<boolean>(() => !isAsapWorkspace.value);

  const teamMembers = ref<TeamMember[]>([]);

  const supabase = useSupabase();

  watchEffect(async () => {
    if (!workspaceId.value) return;

    const { data, error } = await supabase
      .from("workspace")
      .select(workspaceColumns)
      .eq("id", workspaceId.value)
      .single();

    if (error) throw error;

    workspace.value = data;
  });

  watchEffect(async () => {
    if (!workspaceId.value) return;

    const { data: memberships, error: errorMemberships } = await supabase
      .from("team_member_workspace")
      .select()
      .eq("workspace_id", workspaceId.value);

    if (errorMemberships) throw errorMemberships;

    const { data, error } = await supabase
      .from("team_member")
      .select(teamMemberColumns)
      .in(
        "id",
        memberships.map((membership) => membership.team_member_id)
      );

    if (error) throw error;

    teamMembers.value = data;
  });

  return {
    workspace,
    teamMembers,

    isAsapWorkspace,
    isIndependentWorkspace,
  };
});

export type WorkspaceType = "internal" | "external" | "all";

export const useWorkspaceFilter = createSharedComposable(() => {
  const workspaceType = ref<WorkspaceType>("internal");

  return {
    workspaceType,
  };
});
