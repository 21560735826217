export const getFirstName = function (fullName: string) {
  return fullName.split(" ")[0];
};

export const getLastName = function (fullName: string) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [firstName, ...otherParticules] = fullName.split(" ");

  return otherParticules.join(" ");
};

export type People = {
  first_name?: string | null;
  last_name?: string | null;
  temp_first_name?: string | null;
  temp_last_name?: string | null;
  auth_user?: {
    first_name?: string | null;
    last_name?: string | null;
  } | null;
};

export const hasName = function (people: People) {
  if (people.temp_first_name || people.temp_last_name) return true;
  if (people.auth_user?.first_name || people.auth_user?.last_name) return true;
  return false;
};

export const getFullName = function (
  people: People,
  params: { capitalizeLastName: boolean } = { capitalizeLastName: false }
) {
  const firstName = people.auth_user?.first_name ?? people.first_name ?? people.temp_first_name;

  let lastName = people.auth_user?.last_name ?? people.last_name ?? people.temp_last_name;

  lastName = params.capitalizeLastName ? (lastName ?? "").toUpperCase() : lastName;

  return [firstName, lastName].filter(Boolean).join(" ");
};
