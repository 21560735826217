<template>
  <ToastProvider>
    <slot></slot>

    <Toast v-for="toast in toasts" :key="toast.id" :toast="toast" />

    <ToastViewport class="ToastViewport" />
  </ToastProvider>
</template>

<script setup lang="ts">
import { ToastProvider, ToastViewport } from "radix-vue";

const { toasts } = useToast();
</script>

<style lang="scss">
.ToastViewport {
  --viewport-padding: 24px;
  --viewport-width: 384px;
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: var(--viewport-padding);
  gap: 12px;
  width: var(--viewport-width);
  max-width: 100dvw;
  z-index: 9999;
  outline: none;

  // positioned at the top and horizontally centered
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
</style>
