<template>
  <slot></slot>
</template>

<script setup lang="ts">
import { isEqual } from "lodash-es";

const segment = useSegment();

onMounted(() => {
  segment.page();
});

const router = useRouter();

router.afterEach(() => {
  segment.page();
});

const { authUser } = useUserMe();
const { workspace } = useWorkspaceMe();

watch(
  authUser,
  (newValue, oldValue) => {
    if (isEqual(newValue, oldValue)) return;
    segment.identify();
  },
  { immediate: true }
);

watch(workspace, () => {
  segment.group();
});
</script>
