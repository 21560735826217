// This code is meant to fix this error
// "ResizeObserver loop completed with undelivered notifications".
// source: https://github.com/vuejs/vue-cli/issues/7431

/*
"This code ensures that when a ResizeObserver is created, the provided callback function is debounced with a 20ms delay. 
This debouncing can help in handling and preventing excessive calls to the callback, 
especially in scenarios where the ResizeObserver can be triggered frequently."
*/

// Stop error resizeObserver
const debounce = (callback: (...args: any[]) => void, delay: number) => {
  let tid: any;
  return function (...args: any[]) {
    const ctx = self;
    if (tid) clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};

const resizeObserverClient = (window as any).ResizeObserver;
(window as any).ResizeObserver = class ResizeObserver extends resizeObserverClient {
  constructor(callback: (...args: any[]) => void) {
    callback = debounce(callback, 20);
    super(callback);
  }
};
