export const baseFileColumns = `*` as const;

export const baseCompanyColumns = `*` as const;

export const baseAgencyColumns = `*` as const;

export const baseJobColumns = `
  id,
  name,
  workspace_id
` as const;

export const baseTalentSoftwareColumns = `*` as const;

export const baseBusinessUnitColumns = `*` as const;

export const baseTeamMemberColumns = `*` as const;

export const baseWorkspaceColumns = `*` as const;

export const baseTeamMember = `*` as const;

export const baseContact = `*` as const;

export const baseTalentJobExperienceColumns = `*` as const;

export const baseResumeColumns = `
  id,
  talent_id,
  is_anonymized,
  created_at
` as const;

export const baseTalentSearchColumns = `
  id,
  first_name,
  last_name,
  email,
  phone_number,
  address,
  address_extra_info,
  formatted_address,
  birth_date,
  emergency_contact,
  location,
  nationality,
  gender,
  picture_id,
  job_name
` as const;

export const baseReferenceCheckColumns = `
  id,
  created_at,
  updated_at,
  status,
  note,
  reminder_date,
  contact_id
` as const;

export const baseHiringPoolColumns = `*` as const;

export const baseTalentColumns = `
  id,
  created_at,
  first_name,
  last_name,
  email,
  formatted_address,
  phone_number,
  location,
  annual_wage,
  availability_date,
  hourly_wage,
  has_driver_license,
  contract_types,
  construction_types,
  location_as_point,
  location_as_coordinates,
  created_by,
  updated_at,
  updated_by,
  temp_created_by_email,
  temp_updated_by_email,
  experience_level,
  birth_date,
  address,
  has_vehicle,
  travel_preferences,
  mission_types,
  availability_note,
  has_managerial_experience,
  size_of_construction_site,
  emergency_contact,
  nationality,
  gender,
  picture_id,
  salary_expectation,
  anonymous_resume_file_id,
  bio,
  is_opened_to_business_trips,
  long_business_trips_extra_info,
  job_extra_info,
  is_top_profile,
  qualification_status,
  has_auth_access,
  country_of_birth,
  city_of_birth,
  residency_permit_number,
  professional_card_number,
  job_name,
  external_interview_processes,
  availability_status,
  availability_status_updated_at,
  availability_status_updated_by,
  longitude,
  latitude,
  number_of_people_managed,
  address_extra_info,
  owner_id,
  is_benched,
  consultant_recommandation,
  search_full_name,
  job_name_embedding,
  has_no_valid_resume,
  profile_summary_embedding,
  is_foreign_candidate,
  resume_parsing_status,
  resume_parsing_started_at,
  data_extraction_status,
  data_extraction_started_at,
  profile_summary_status,
  profile_summary_started_at,
  duplicate_of
` as const;

export const baseContractColumns = `*` as const;

export const baseContractForTableColumns = `
  id,
  start_date,
  end_date,
  status,
  created_at,
  amendment_type,
  contract_key,
  hourly_wage,
  coefficient,
  is_definitive_end,
  real_end_date,
  previous_contract_key,
  previous_contract_key,
  definitive_end_type,
  is_signature_on_worksite_possible
` as const;

export const baseMissionColumns = `*` as const;

export const baseApplicationColumns = `*` as const;

export const baseQualificationCertificateColumns = `*` as const;

export const baseQualificationTrainingRequestColumns = `*` as const;

export const baseQualificationCenterColumns = `
  id,
  name,
  address,
  location,
  email,
  phone_number,
  website,
  status,
  training_type
` as const;

export const baseQualificationCenterForTableColumns = `
  id,
  name,
  address,
  location,
  email,
  phone_number,
  website,
  status
` as const;

export const baseIndependentPaymentRequestColumns = `
  id,
  month,
  amount,
  comment,
  status
` as const;

export const baseDocumentColumns = `
  id,
  type,
  expiry_date,
  docuseal_submission_url,
  docuseal_submission_id,
  docuseal_template_id
` as const;

export const baseRecruitmentOfficeInvoiceRequestForTableColumns = `
  id,
  status,
  annual_wage,
  job_name
` as const;

export const baseRecruitmentOfficeInvoiceRequestColumns = `
  id,
  status,
  annual_wage,
  pricing,
  start_date,
  comment,
  timeline,
  job_name
` as const;

export const baseMedicalCheckupRequestColumns = `*` as const;

export const baseMedicalCheckupRequestForTableColumns = `
  id,
  status,
  created_at,
  workspace_id,
  scheduled_at,
  near_to,
  medical_center_id,
  medical_checkup_type
` as const;

export const baseMedicalCenterColumns = `
  id,
  name,
  address,
  location,
  email,
  phone_number,
  website,
  status
` as const;

export const baseProfessionalCardRequestColumns = `*` as const;

export const baseExpenseClaimRequestColumns = `*` as const;

export const baseAdvancePaymentRequestColumns = `*` as const;

export const basePpeRequestColumns = `*` as const;

export const baseProjectTypologyColumns = `*` as const;

export const baseSpecialtyColumns = `*` as const;

export const baseNoteColumns = `
  id,
  created_at,
  updated_at,
  content,
  talent_id,
  contact_id
` as const;

export const baseNotificationColumns = `
  id,
  created_at,
  recipient_roles,
  data,
  is_read,
  type
` as const;

export const baseContractForTimesheetColumns = `
  job_name
` as const;

export const baseContractForTimesheetLinkColumns = `*` as const;

export const baseContractForSelectTimesheetColumns = `
  id,
  contract_key,
  start_date,
  end_date,
  real_end_date,
  timesheet_launch_date,
  amendment_type,
  working_days
` as const;

export const baseTimesheetSignerColumns = `
  is_worksite_signer
` as const;

export const baseCommercialConditionColumns = `*` as const;

export const baseTimesheetWeekColumns = `
  status,
  contract_key,
  date
` as const;

export const baseCompanyInsuranceRequestColumns = `*` as const;

export const baseViewContractColumns = `*` as const;

export const baseUserForTableColumns = `
  id,
  email,
  first_name,
  last_name
` as const;

export const baseCooptationForTableColumns = `*` as const;

export const baseTalentProjectTypologyColumns = `*` as const;

export const baseOnboardingRequestColumns = `*` as const;

export const basesmsColumns = `
  talent_id,
  send_at,
  content,
  is_sent
` as const;

export const baseContactColumns = `*` as const;

export const baseTalentJobColumns = `*` as const;

export const baseAcquisitionColumns = `*` as const;

export const baseMissionWorkspaceColumns = `*` as const;

export const emailBaseColumns = `*` as const;
