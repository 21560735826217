import type { AddressWithGeography } from "./supabase.types";

interface LngLat {
  lng: number;
  lat: number;
}

export function convertCoordinatesToWkt({ lng, lat }: LngLat): string {
  const wkt = `POINT(${lng} ${lat})`; // WKT stands for well-known text: https://postgis.net/docs/using_postgis_dbmanagement.html#OpenGISWKBWKT

  return wkt;
}

const distanceInKm = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const earthRadiusInKm = 6371;
  const p = Math.PI / 180;

  const a =
    0.5 -
    Math.cos((lat2 - lat1) * p) / 2 +
    (Math.cos(lat1 * p) * Math.cos(lat2 * p) * (1 - Math.cos((lon2 - lon1) * p))) / 2;

  return 2 * earthRadiusInKm * Math.asin(Math.sqrt(a));
};

export const calculateDistanceFromTo = (fromAddress: AddressWithGeography, toAddress: AddressWithGeography): number =>
  distanceInKm(
    toAddress.coordinates.lat,
    toAddress.coordinates.lng,
    fromAddress.coordinates.lat,
    fromAddress.coordinates.lng
  );

const kilometersToLongitudeDegrees = (distanceInKilometers: number) => {
  const earthEquatorCircumferenceMeters = 40_075; // Earth's circumference at the equator in kilometers
  const kilometers = earthEquatorCircumferenceMeters / 360; // The number of meters in one degree
  return distanceInKilometers / kilometers;
};

const kilometersToLatitudeDegrees = (distanceInKilometers: number) => {
  // The Earth is not a perfect sphere but an oblate spheroid, meaning it's wider at the equator than at the poles.
  // Therefore, the distance represented by one degree of longitude varies depending on the latitude.
  // However, one degree of latitude remains fairly constant and is approximately 111.045 kilometers.
  // So, to convert kilometers to latitude degrees, we can simply divide the distance by 111.045.
  const kilometersPerDegree = 111.045; // The number of kilometers in one degree of latitude
  return distanceInKilometers / kilometersPerDegree;
};

export const getBounds = function ({ lng, lat }: LngLat, deltaInKilometers: number) {
  const deltaLng = kilometersToLongitudeDegrees(deltaInKilometers / 2);
  const deltaLat = kilometersToLatitudeDegrees(deltaInKilometers / 2);

  const minLng = lng - deltaLng;
  const maxLng = lng + deltaLng;

  const minLat = lat - deltaLat;
  const maxLat = lat + deltaLat;

  return { minLng, maxLng, minLat, maxLat };
};
