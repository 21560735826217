import { config, library } from "@fortawesome/fontawesome-svg-core";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faArrowLeft, faCaretDown, faCaretUp, faSort, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// TODO: Cleanup these icons to only use Lucide icons, and remove the dependency on Font Awesome
const iconsToAdd = [
  // For Login
  faGoogle,

  // For ButtonEscape
  faArrowLeft,
  faTimes,

  // For CellHeader
  faCaretDown,
  faCaretUp,
  faSort,
];

library.add(...iconsToAdd);

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false;

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component("FontAwesomeIcon", FontAwesomeIcon);
});
