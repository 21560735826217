import { AnalyticsBrowser, type EventProperties } from "@segment/analytics-next";
import type { TeamMember } from "~/utils/supabase.types";

export const useSegment = createSharedComposable(function () {
  const teamMember = ref<TeamMember>();

  const config = useRuntimeConfig();

  const analytics = AnalyticsBrowser.load({ writeKey: config.public.SEGMENT_KEY });

  const supabaseAuthUser = useSupabaseUser();

  const supabase = useSupabase();

  watchEffect(async () => {
    if (!supabaseAuthUser.value) return;

    const { data: teamMemberData } = await supabase
      .from("team_member")
      .select(teamMemberColumns)
      .eq("auth_user_id", supabaseAuthUser.value.id)
      .maybeSingle();

    if (teamMemberData) teamMember.value = teamMemberData;
  });

  const { environment } = useFeatureFlag();

  const context = computed(() => ({ app: { name: "back_office", env: environment } }));

  const identify = function () {
    if (!teamMember.value?.auth_user) return;

    analytics.identify(
      teamMember.value.auth_user.id,
      {
        /*
         * Segment Specs
         */
        first_name: teamMember.value.auth_user.first_name,
        last_name: teamMember.value.auth_user.last_name,
        email: teamMember.value.auth_user.email,

        /*
         * Custom Specs
         */
        is_team_member: !!teamMember.value,
      },
      { context: { ...context.value } }
    );
  };

  const reset = function () {
    analytics.reset();
  };

  const group = function () {
    if (!teamMember.value?.current_workspace) return;

    analytics.group(
      teamMember.value.current_workspace.id,
      {
        name: teamMember.value.current_workspace.name,
        createdAt: teamMember.value.current_workspace.created_at,
      },
      { context: { ...context.value } }
    );
  };

  const track = function (eventName: string, properties?: EventProperties) {
    if (!teamMember.value?.auth_user) return;

    const augmentedProps: EventProperties = {
      ...(properties ?? {}),
    };

    analytics.track(eventName, augmentedProps, {
      context: { ...context.value, groupId: teamMember.value.current_workspace?.id },
    });
  };

  return { ...analytics, identify, reset, group, track };
});
