import type { LucideIconName } from "~/components/lucide-icon/types";
import type { Database } from "~/types.gen";

type Table = keyof Database["public"]["Tables"];

const mapTableIcon: Partial<Record<Table, LucideIconName>> = {
  advance_payment_request: "PiggyBank",
  company_insurance_request: "ShieldCheck",
  independent_payment_request: "ReceiptText",
  recruitment_office_invoice_request: "Receipt",
  agency: "Carrot",
  application: "GalleryHorizontalEnd",
  acquisition: "Filter",
  business_unit: "Pyramid",
  company: "Building",
  company_group: "Building2",
  contact: "UserCircle2",
  contract: "Handshake",
  cooptation: "HandHeart",
  document: "PackageOpen",
  expense_claim_request: "ScrollText",
  file: "File",
  job: "HardHat",
  job_public: "HardHat",
  medical_checkup_request: "ShieldPlus",
  mission: "TrafficCone",
  medical_center: "Cross",
  professional_card_request: "Contact2",
  project_typology: "Cuboid",
  qualification_certificate: "Album",
  qualification_training_request: "BadgeCheck",
  specialty: "Atom",
  team_member: "Users2",
  talent: "Bird",
  timesheet_day: "ClipboardSignature",
  timesheet_week: "ClipboardSignature",
  workspace: "Globe",
  commercial_condition: "HeartHandshake",
  notification: "Bell",
  hiring_pool: "Pickaxe",
} as const;

type KeysOfUnion<T> = T extends T ? keyof T : never;

type Column = KeysOfUnion<Database["public"]["Tables"][Table]["Row"]>;

const mapColumnIcon: Partial<Record<Column, LucideIconName>> = {
  address: "MapPin",
  amount: "BadgeEuro",
  birth_date: "Cake",
  coefficient: "SquareAsterisk",
  compensation_bonus_with_charges: "ParkingMeter",
  compensation_bonus_without_charges: "CircleOff",
  compensation_bonus_others: "MoreHorizontal",
  date: "Calendar",
  description: "FileSignature",
  definitive_end_type: "BringToFront",
  email: "AtSign",
  status: "CircleDot",
  is_amendment: "FilePlus",
  is_definitive_end: "FileX",
  is_termination_bonus_paid_monthly: "PiggyBank",
  is_termination_bonus_to_be_paid: "PiggyBank",
  job_description: "HardHat",
  location: "MapPin",
  mastery_level: "BarChart",
  mission_statement_description: "FileText",
  mission_types: "FileBadge2",
  nationality: "Flag",
  note: "StickyNote",
  overtime: "CalendarPlus",
  phone_number: "Phone",
  ppe: "HardHat",
  construction_type: "Cuboid",
  sirh_name: "Cable",
  real_end_date: "CalendarCheck",
  refund_schedule: "IterationCcw",
  working_hours: "CalendarClock",
  job_title: "Contact",
  bio: "TextQuote",
  created_at: "Zap",
  training_type: "LibraryBig",
  refusal_reason: "XCircle",
  website: "PanelTop",
  annual_wage: "HandCoins",
  external_interview_processes: "ChevronRightCircle",
} as const;

type Enum = KeysOfUnion<Database["public"]["Enums"]>;

const mapEnumIcon: Partial<Record<Enum, LucideIconName>> = {
  acquisition_source: "Filter",
  app_role: "Flower",
  contract_reason: "FileQuestion",
  employee_status: "BookUser",
  job_risk: "Skull",
  medical_checkup_type: "Stethoscope",
  job_qualification_level: "Signpost",
  month: "CalendarDays",
  application_stage: "GalleryHorizontalEnd",
} as const;

const mapUtilIcon = {
  worksite: "TrafficCone",
  arrow_left: "ArrowLeft",
  arrow_right: "ArrowRight",
  badge: "UserSquare",
  bank_note: "Banknote",
  bell: "Bell",
  jobs: "ConciergeBell",
  building: "Building",
  camera: "Camera",
  circle: "Circle",
  details: "Container",
  duration: "Hourglass",
  delete: "Trash2",
  car: "CarFront",
  calendar_check: "CalendarCheck",
  card: "CreditCard",
  check: "Check",
  check_circle: "CheckCircle2",
  check_square: "CheckSquare",
  chevron_down: "ChevronDown",
  chevron_left: "ChevronLeft",
  chevron_right: "ChevronRight",
  chevron_up: "ChevronUp",
  chevron_up_down: "ChevronsUpDown",
  clock: "Clock2",
  calculator: "Calculator",
  comment: "PencilLine",
  construction: "Construction",
  copy: "Copy",
  create: "Plus",
  dot_separator: "Dot",
  edit: "Pencil",
  expand: "Maximize2",
  question_file: "FileQuestion",
  hat: "HardHat",
  hashtag: "Hash",
  image: "Image",
  list_check: "ListChecks",
  lock: "Lock",
  planning: "Calendar",
  private: "Lock",
  public: "Unlock",
  logout: "DoorOpen",
  love: "Heart",
  map: "Map",
  message: "MessageCircle",
  money: "CircleDollarSign",
  more_horizontal: "MoreHorizontal",
  more_vertical: "MoreVertical",
  multidiffusion: "Radio",
  open: "ArrowUpRight",
  modify: "ArrowUpDown",
  rating_star: "Star",
  close: "X",
  remove: "X",
  remove_circle: "XCircle",
  search: "Search",
  smile: "Smile",
  software: "Laptop",
  thumbs_up: "ThumbsUp",
  thumbs_down: "ThumbsDown",
  tower_broadcast: "RadioTower",
  transport: "Bus",
  traffic_cone: "TrafficCone",
  upload_cloud: "UploadCloud",
  user_group: "Users",
  merge: "Merge",
  share: "Share2",
  shortlist: "UserRoundPlus",
  template: "UserRoundPen",
  unshortlist: "UserRoundX",
  sheet: "PanelLeftClose",
  invite: "Mail",
  smartphone: "Smartphone",
  independent: "Rabbit",
  table: "Table",
  sun: "SunMedium",
  moon: "Moon",
  long_trips: "Luggage",
  anonymous_resume: "VenetianMask",
  download: "ArrowDownToLine",
  fileDownload: "FileDown",
  external_link: "ArrowUpRightSquare",
  work_accident_request: "Ambulance",
  warning: "AlertTriangle",
  archive: "Archive",
  sniper_view: "Eye",
  previous: "CircleChevronLeft",
  next: "CircleChevronRight",
  ai_suggestion: "Sparkles",
  bot: "Bot",
  fail: "CircleX",
  shared_from_external_workspace: "Share2",
  target: "Crosshair",
  job_posting: "GanttChartSquare",
  profile: "CircleUserRound",
  users: "Users",
  role: "Key",
  ship: "Ship",
  meal_voucher: "CookingPot",
  repeat: "Repeat",
  school: "School",
  filter: "ListFilter",
  dashboard_action_center: "GalleryVerticalEnd",
  send: "Send",
  megaphone: "Megaphone",
  armchair: "Armchair",
  qualification: "DraftingCompass",
  eye_off: "EyeOff",
  network: "Network",
  statistics: "ChartBar",
  undo: "Undo",
} as const;

export const mapIcon = {
  ...mapTableIcon,
  ...mapColumnIcon,
  ...mapEnumIcon,
  ...mapUtilIcon,
} as const;
