import type { AppRole, NotificationType } from "./supabase.enum";

const imageMimetypes = ["image/jpeg", "image/png"] as const;
export const pdfMimetypes = ["application/pdf"] as const;

export const documentMimetypes = [...pdfMimetypes, ...imageMimetypes] as const;
export const officeMimetypes = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
] as const;
export const heicMimetypes = ["image/heic", "image/heif"] as const;

export const businessUnitNames = [
  "CET",
  "TP / VRD",
  "Bâtiment & Gros oeuvre",
  "Télécom et énergies",
  "Construction éphémère",
  "Management de projets",
  "Clos et couvert",
] as const;

export const importantNotifications: {
  [key in AppRole]?: NotificationType[];
} = {
  team_member_manager: ["new_contract_to_process", "new_commercial_condition_created"],
};

export const boardManagerEmails = [
  "baptiste@asap.work",
  "emilie.vialle@asap.work",
  "mathias@asap.work",
  "steve@asap.work",
  "jimmy@asap.work",
  "julien.gaultier@asap.work",
  "francois.gouzon@asap.work",
  "florent.pasquiou@asap.work",
  "guenael@asap.work",
] as const;

export const dashboardKeys = ["prodBoardManager", "prodConsultant", "prodConsultantWithManagedTeamMembers"] as const;

export const asapWorkWorkspaceId = "9af65ff5-1e16-474e-9a0f-1ac6d74832e2";
